import { useState, useEffect } from 'react';
import { TABLET_BREAKPOINT, DESKTOP_BREAKPOINT, LARGE_DESKTOP_BREAKPOINT } from 'utils/constants';
import { UseScreenRecognitionHook } from './model';

const useScreenRecognition: UseScreenRecognitionHook = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  const [isNotMobile, setIsNotMobile] = useState(false);
  const [isLargeDesktop, setIsLargeDesktop] = useState(false);

  const handleResize = () => {
    const width = window.innerWidth;

    setIsMobile(width < TABLET_BREAKPOINT);
    setIsNotMobile(width >= TABLET_BREAKPOINT);
    setIsTablet(width >= TABLET_BREAKPOINT && width < DESKTOP_BREAKPOINT);
    setIsDesktop(width >= DESKTOP_BREAKPOINT);
    setIsLargeDesktop(width >= LARGE_DESKTOP_BREAKPOINT);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    isMobile,
    isTablet,
    isDesktop,
    isNotMobile,
    isLargeDesktop,
  };
};

export default useScreenRecognition;
