import React, { forwardRef } from 'react';
import cx from 'classnames';

import ExternalLink from '../ExternalLink';
import { IButton } from './models';
import './Button.scss';
import './ButtonOverride.scss';

const Button = forwardRef<HTMLButtonElement, IButton>(
  (
    {
      to,
      classes,
      variant,
      type,
      children,
      clickHandler,
      ariaLabel,
      activeClassName,
      linkState,
      ...rest
    },
    ref
  ) => {
    const cn = cx('gs-btn', `gs-btn--${variant}`, { [`${classes}`]: classes });

    return to ? (
      <ExternalLink
        className={cn}
        {...{
          to,
          activeClassName,
          ...linkState,
          ...(linkState && { state: linkState }),
          ariaLabel,
          ...rest,
        }}
      >
        {children}
      </ExternalLink>
    ) : (
      <button
        ref={ref}
        onClick={clickHandler}
        type={type === 'submit' ? 'submit' : 'button'}
        aria-label={ariaLabel}
        className={cn}
        {...rest}
      >
        {children}
      </button>
    );
  }
);

export default Button;
